import { get_api } from "api";
import dayjs from "dayjs";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useContactUsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactUsList, setContactUsList] = useState(false);

  const getAllContactUs = async () => {
    try {
      const { data } = await get_api("contact-us");
      setContactUsList(data.contactDetails);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllContactUs();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            <h6 className="m-0 text-primary text-capitalize">{`${text} ${record.last_name}`}</h6>
            {record?.email && <p className="m-0 text-muted">{record.email}</p>}
          </div>
        </div>
      ),
    },
    {
      title: "Enquiry Type",
      dataIndex: "enquiry_type",
      key: "enquiry_type",
      render: (text, record) => <div key={record._id}>{startCase(text)}</div>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, record) => <div key={record._id}>{text}</div>,
    },
    {
      title: "Received On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
    },
  ];

  return {
    columns,
    contactUsList,
    isLoading,
  };
};

export default useContactUsList;
