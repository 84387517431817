import { Button, Input, Modal, Spin, Table } from "antd";
import React from "react";
import useEquipmentsListHook from "./useEquipmentsListHook";
import { Label } from "reactstrap";
import deleteIcon from "../../assets/img/delete-icon.svg";

const EquipmentsList = () => {
  const {
    columns,
    equipmentsList,
    isLoading,
    openModal,
    setOpenModal,
    equipmentsData,
    setEquipmentsData,
    handleTextboxChange,
    handleRemoveData,
    handleAddTextbox,
    handleSubmitData,
  } = useEquipmentsListHook();

  return (
    <div className="content mb-0">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="page-header-wrap">
            <h2 className="page-main-title">
              Equipment
            </h2>
            <Button className="header-btn-wrap" onClick={() => setOpenModal(true)}>Add Equipment</Button>
          </div>
          <Table
            columns={columns}
            dataSource={equipmentsList}
            rowKey="_id"
            pagination={false}
            scroll={equipmentsList.length > 0 ? { y: 670, x: 450 } : undefined}
          />
          <Modal
            className="common-admin-popup"
            open={openModal}
            onCancel={() => {
              setOpenModal(false);
              setEquipmentsData([{ name: "" }]);
            }}
            centered
            destroyOnClose
            onOk={handleSubmitData}
            width={700}
          >
            <div className="add-requirement-field">
              <Label className="common-stepper-label">Equipment</Label>
              {equipmentsData.map((eqpmnt, index) => (
                <div
                  className="add-required-field-wrapper"
                  key={index}
                > 
                  {equipmentsData.length > 1 && (
                    <Button
                      className="delete-icon-wrap"
                      color="link"
                      onClick={() => handleRemoveData(index)}
                    >
                      <img src={deleteIcon} alt="delete icon" />
                    </Button>
                  )}
                  <div className="add-requirement-input-wrap">
                    <Input
                      type="text"
                      className="stepper-input-field"
                      placeholder="Enter Equipment"
                      value={eqpmnt.name}
                      onChange={(e) =>
                        handleTextboxChange(e.target.value, index)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            {equipmentsData.length < 3 && (
              <div className="add-other-field">
                <Button
                  className="add-field-btn"
                  color="link"
                  onClick={handleAddTextbox}
                >Add <span className="add-icon">+</span>
                </Button>
              </div>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default EquipmentsList;
