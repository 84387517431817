import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Popconfirm, Space } from "antd";
import { post_api } from "api";
import { delete_api } from "api";
import { put_api } from "api";
import { get_api } from "api";
import dayjs from "dayjs";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useLanguagesListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [flag, setFlag] = useState(null);
  const [languageName, setLanguageName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const getAllLanguages = async () => {
    try {
      const { data } = await get_api("language/all");
      setLanguagesList(data.allLanguages);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllLanguages();
  }, []);

  const handleDeleteData = async (id) => {
    try {
      const { data, message } = await delete_api(`language/delete/${id}`);
      toast.success(message);
      await getAllLanguages();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleDeleteData ~ err:", err);
      toast.error(err.message);
    }
  };

  const handleEditData = (record) => {
    setCountryName(record.name);
    setLanguageName(record.language);
    setFlag(record.flag);
    setRecordId(record._id);
    setIsEditing(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditing(false);
    setRecordId(null);
  };

  const columns = [
    {
      title: "Country",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="tbl-user-details">
            <Avatar icon={<UserOutlined />} src={record?.flag} />
            <h6 className="m-0 text-primary text-capitalize">
              {startCase(text)}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <Button
            className="action-btn"
            onClick={() => handleEditData(record)}
            icon={<i className="fas fa-edit" />}
          />
          <Popconfirm
            title="Delete the Language"
            description="Are you sure to delete this Language?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => handleDeleteData(record._id)}
          >
            <Button
              className="action-btn"
              icon={<i className="fas fa-trash-alt text-danger" />}
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSubmitData = async (payload) => {
    try {
      const { data, message } = await post_api("language/create", payload);
      setOpenModal(false);
      toast.success(message);
      await getAllLanguages();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleSubmitData ~ err:", err);
      toast.error(err.message);
    }
  };

  const handleFlagChange = (info) => {
    if (info.file && info.file.status === "done") {
      setFlag(info.file.originFileObj);
    } else if (info.file) {
      setFlag(info.file);
    }
    return false; // Prevent auto-upload
  };

  const handleUpdateData = async (id, payload) => {
    try {
      const { data, message } = await put_api(`language/update/${id}`, payload); // Adjust API endpoint
      toast.success(message);
      await getAllLanguages();
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleFormSubmit = async () => {
    const formData = new FormData();
    formData.append("name", countryName);
    formData.append("language", languageName);
    if (flag && typeof flag !== "string") {
      formData.append("flag", flag);
    }

    try {
      if (isEditing) {
        await handleUpdateData(recordId, formData);
      } else {
        await handleSubmitData(formData);
      }
      setCountryName("");
      setFlag(null);
      setLanguageName("");
      setOpenModal(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return {
    columns,
    languagesList,
    isLoading,
    openModal,
    setOpenModal,
    handleSubmitData,
    handleFlagChange,
    handleFormSubmit,
    countryName,
    setCountryName,
    flag,
    languageName,
    setLanguageName,
    handleCloseModal,
  };
};

export default useLanguagesListHook;
