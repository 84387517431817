import React from "react";
import Dashboard from "views/Dashboard.js";
import RoutesName from "variables/route";
import BusinessUsers from "components/BusinessUsers";
import LocumUsersList from "components/LocumUsers";
import CmsPagesList from "components/CmsPages";
import ContactUsList from "components/ContactUs";
import PostShiftList from "components/PostShift";
import LocumShiftsList from "components/LocumShifts";
import ActiveLocumsOfShifts from "components/ActiveLocumsOfShifts";
import CompanyProfile from "components/CompanyProfile";
import AhpraRequestsList from "components/AhpraRequests";
import EditCmsPages from "components/CmsPages/EditCmsPages";
import ServicesList from "components/Services";
import SoftwaresList from "components/Softwares";
import EquipmentsList from "components/Equipments";
import LanguagesList from "components/Languages";
import InterestsList from "components/Interests";
import FaqList from "components/Faq";

const {
  dashboard,
  business_users,
  locum_users,
  cms_pages,
  contact_us_list,
  ahpra_requests,
  edit_cms_pages,
  services_list,
  softwares_list,
  equipments_list,
  languages_list,
  interests_list,
  faqs_list
} = RoutesName;

var routes = [
  {
    path: dashboard,
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    inSidebar: true,
  },
  {
    path: business_users,
    name: "Businesses",
    icon: "nc-icon nc-bank",
    component: <BusinessUsers />,
    inSidebar: true,
  },
  {
    path: `${business_users}/:id/view`,
    name: "Company Profile",
    icon: "nc-icon nc-bank",
    component: <CompanyProfile />,
    inSidebar: false,
  },
  {
    path: `${business_users}/:id/post-shift`,
    name: "Post Shifts of Business User",
    icon: "nc-icon nc-bank",
    component: <PostShiftList />,
    inSidebar: false,
  },
  {
    path: `${business_users}/:id/post-shift/:shiftId`,
    name: "Active Locum Users of the shift",
    icon: "nc-icon nc-bank",
    component: <ActiveLocumsOfShifts />,
    inSidebar: false,
  },
  {
    path: locum_users,
    name: "Locums",
    icon: "nc-icon nc-circle-10",
    component: <LocumUsersList />,
    inSidebar: true,
  },
  {
    path: ahpra_requests,
    name: "AHPRA Requests",
    icon: "nc-icon nc-bank",
    component: <AhpraRequestsList />,
    inSidebar: true,
  },
  {
    path: `${locum_users}/:id/shifts`,
    name: "All shifts of Locum User",
    icon: "nc-icon nc-bank",
    component: <LocumShiftsList />,
    inSidebar: false,
  },
  {
    path: cms_pages,
    name: "CMS Pages",
    icon: "nc-icon nc-bank",
    component: <CmsPagesList />,
    inSidebar: true,
  },
  {
    path: edit_cms_pages,
    name: "Edit CMS Pages",
    icon: "nc-icon nc-bank",
    component: <EditCmsPages />,
    inSidebar: false,
  },
  {
    path: contact_us_list,
    name: "Enquiries",
    icon: "nc-icon nc-bank",
    component: <ContactUsList />,
    inSidebar: true,
  },
  {
    path: services_list,
    name: "Services",
    icon: "nc-icon nc-bank",
    component: <ServicesList />,
    inSidebar: true,
  },
  {
    path: softwares_list,
    name: "Software",
    icon: "nc-icon nc-bank",
    component: <SoftwaresList />,
    inSidebar: true,
  },
  {
    path: equipments_list,
    name: "Equipment",
    icon: "nc-icon nc-bank",
    component: <EquipmentsList />,
    inSidebar: true,
  },
  {
    path: languages_list,
    name: "Languages",
    icon: "nc-icon nc-bank",
    component: <LanguagesList />,
    inSidebar: true,
  },
  {
    path: interests_list,
    name: "Interests",
    icon: "nc-icon nc-bank",
    component: <InterestsList />,
    inSidebar: true,
  },
  {
    path: faqs_list,
    name: "FAQs",
    icon: "nc-icon nc-bank",
    component: <FaqList />,
    inSidebar: true,
  },
];

export default routes;
