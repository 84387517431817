import { Spin, Table } from "antd";
import React from "react";
import useBusinessUsersListHook from "./useBusinessUsersListHook";

const BusinessUsersList = () => {
  const { columns, companyProfileList, isLoading } = useBusinessUsersListHook();
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">
        Business Users
      </h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          rowClassName={() => "custom-table-row"}
          columns={columns}
          dataSource={companyProfileList}
          rowKey="_id"
          pagination={false}
          scroll={companyProfileList.length > 0 ? { y: 670, x: 1300} : undefined}
        />
      )}
    </div>
  );
};

export default BusinessUsersList;
