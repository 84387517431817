/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import RoutesName from "variables/route";

function AdminLayout(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  const { dashboard } = RoutesName;
  const navigate = useNavigate();
  let ps;

  useEffect(() => {
    if (mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current, {
        wheelSpeed: 0.5,
        wheelPropagation: true,
        minScrollbarLength: 20,
      });
    }

    return () => {
      if (ps) {
        ps.destroy();
      }
    };
  }, [mainPanel]);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <DemoNavbar {...props} />
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />

      <div className={"main-panel admin-main-body"} >
        <div className="admin-panel-content" ref={mainPanel}>
          <Routes>
            {routes.map((prop, key) => (
              <Route
                path={prop.path}
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    {prop.component}
                  </React.Suspense>
                }
                key={key}
                exact
              />
            ))}
          </Routes>
          {/* <Footer fluid /> */}
        </div>  
      </div>
    </div>
  );
}

export default AdminLayout;
