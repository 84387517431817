/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Spin } from "antd";
import { get_api, post_api } from "api";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import he from "he";
import 'react-quill/dist/quill.snow.css'; 
import Quill from 'quill'; // Import Quill

// Register custom font sizes
const Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '24px', '32px', '48px']; // Custom sizes in px
Quill.register(Size, true);
const EditCmsPages = () => {
  const params = useParams();
  const [cmsData, setCmsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      [{ size: ['12px' , '14px', '16px', '18px', '24px', '32px', '48px'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],      
      [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#454545', '#023a28'] }, { 'background': [] }],
      ['link', 'image'],
      ['clean'] // remove formatting button
    ],
  };

  const formats = [
    'header', 'font', 'size', 'weight',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', 'align'
  ];
    

  const getCmsPage = async () => {
    try {
      setLoading(true);
      const { data } = await get_api(`cms/${params.id}`);
      setCmsData(data?.cmsPages);
      setContent(he.decode(data?.cmsPages?.content) || "");
      setLoading(false);
    } catch (err) {
      console.log("🚀 ~ getCmsPage ~ err:", err);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { message, data } = await post_api(`cms/update-cms`, {
        id: params.id,
        content,
      });
      console.log("🚀 ~ handleSave ~ response:", data);
      toast.success(message);
      setLoading(false);
      navigate("/cms-pages");
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCmsPage();
  }, []);

  return (
    
    <div className="content mb-0">
      <div className="admin-cms-page">
        {loading && <Spin size="large" fullscreen />}
        <h4 className="page-main-title">{cmsData?.name}</h4>
        <ReactQuill modules={modules} formats={formats} theme="snow" value={content} onChange={setContent} />
        <div className="footer-btn-wrap">
          <Button
            className="common-footer-btn cancel-btn-wrap"
            onClick={() => navigate("/cms-pages")}
          >
            Cancel
          </Button>
          <Button
            className="common-footer-btn save-btn-wrap"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditCmsPages;
