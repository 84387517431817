const RoutesName = {
  dashboard: "/dashboard",
  signin: "/signin",
  business_users: "/business-users",
  locum_users: "/locum-users",
  ahpra_requests: "/ahpra-requests",
  cms_pages: "/cms-pages",
  edit_cms_pages: "/cms-pages/:id",
  contact_us_list: "/inquiry-list",
  services_list: "/services",
  softwares_list: "/softwares",
  equipments_list: "/equipments",
  languages_list: "/languages",
  interests_list: "/interests",
  faqs_list: "/faqs",
};

export default RoutesName;
