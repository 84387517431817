/*eslint-disable react-hooks/exhaustive-deps */
import { Space } from "antd";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toHoursAndMinutes } from "components/GeneralComponents/Utils";
import { unPaidHoursList } from "components/GeneralComponents/Utils";

const usePostShiftListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [postShifList, setPostShifList] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const getPostShiftsByCompanyProfileId = async () => {
    try {
      const { data } = await get_api(`company-profile/${params.id}/post-shift`);
      setPostShifList(data.postShifts);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getPostShiftsByCompanyProfileId();
  }, []);

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div key={record._id}>
          <h6>{dayjs(text).locale("en").format("ddd DD MMM YYYY")}</h6>
          <p>
            {record.start_time} - {record.end_time}
          </p>
        </div>
      ),
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => <div key={record._id}>{text}</div>,
    },
    {
      title: "Unpaid",
      dataIndex: "unpaid_hours",
      key: "unpaid_hours",
      render: (text, record) => {
        const unPaidHour = unPaidHoursList.find((uph) => uph?.value === text);
        const uphours = toHoursAndMinutes(unPaidHour?.value.split("-")[0]);
        return <div key={record._id}>{uphours}</div>;
      },
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      key: "hourly_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {text}</div>;
      },
    },
    {
      title: "Total Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {text}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return <div key={record._id}>{startCase(text)}</div>;
      },
    },
    {
      title: "Active Users",
      dataIndex: "active_users",
      key: "active_users",
      render: (text, record) => {
        return <div key={record._id}>{record?.active_shift_count}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/business-users/${record?.company_profile_id}/post-shift/${record?._id}`
              )
            }
          />
        </Space>
      ),
      align: "center",
    },
  ];

  return {
    columns,
    data: postShifList,
    isLoading,
  };
};

export default usePostShiftListHook;
