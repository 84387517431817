import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space } from "antd";
import { post_api } from "api";
import { delete_api } from "api";
import { put_api } from "api";
import { get_api } from "api";
import dayjs from "dayjs";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useServicesListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [servicesData, setServicesData] = useState([{ name: "" }]);
  const [editingKey, setEditingKey] = useState("");
  const [editingValue, setEditingValue] = useState("");

  const handleTextboxChange = (value, index) => {
    const updatedServicesData = [...servicesData];
    updatedServicesData[index].name = value;
    setServicesData(updatedServicesData);
  };

  const handleRemoveData = (index) => {
    const updatedServicesData = servicesData.filter((_, idx) => idx !== index);
    setServicesData(updatedServicesData);
  };

  const handleAddTextbox = () => {
    setServicesData([...servicesData, { name: "" }]);
  };

  const getAllServices = async () => {
    try {
      const { data } = await get_api("service/all");
      setServicesList(data.allServices);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllServices();
  }, []);

  const handleDeleteData = async (id) => {
    try {
      const { data, message } = await delete_api(`service/${id}`);
      toast.success(message);
      await getAllServices();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleDeleteData ~ err:", err);
      toast.error(err.message);
    }
  };

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    setEditingKey(record._id);
    setEditingValue(record.name);
  };

  const save = async (record) => {
    try {
      const { data, message } = await put_api(`service/${record._id}`, {
        name: editingValue,
      });
      toast.success(message);
      await getAllServices();
      setEditingKey("");
      setEditingValue("");
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={() => save(record)}
            onPressEnter={() => save(record)}
          />
        ) : (
          <div onDoubleClick={() => edit(record)}>
            <h6 className="m-0 text-primary text-capitalize">
              {startCase(text)}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <Popconfirm
            title="Delete the Service"
            description="Are you sure to delete this Service?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => handleDeleteData(record._id)}
          >
            <Button
              className="action-btn"
              icon={<i className="fas fa-trash-alt text-danger" />}
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSubmitData = async () => {
    try {
      const removeEmpty = servicesData.filter((sd) => sd.name !== "");
      const { data, message } = await post_api("service/create", removeEmpty);
      setOpenModal(false);
      setServicesData([{ name: "" }]);
      toast.success(message);
      await getAllServices();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleSubmitData ~ err:", err);
      toast.error(err.message);
    }
  };

  return {
    columns,
    servicesList,
    isLoading,
    openModal,
    setOpenModal,
    servicesData,
    setServicesData,
    handleTextboxChange,
    handleRemoveData,
    handleAddTextbox,
    handleSubmitData,
  };
};

export default useServicesListHook;
