import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space } from "antd";
import { delete_api } from "api";
import { post_api } from "api";
import { put_api } from "api";
import { get_api } from "api";
import dayjs from "dayjs";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useInterestsListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [interestsList, setInterestList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [interestData, setInterestData] = useState([{ name: "" }]);
  const [editingKey, setEditingKey] = useState("");
  const [editingValue, setEditingValue] = useState("");

  const handleTextboxChange = (value, index) => {
    const updatedInterestData = [...interestData];
    updatedInterestData[index].name = value;
    setInterestData(updatedInterestData);
  };

  const handleRemoveData = (index) => {
    const updatedInterestData = interestData.filter((_, idx) => idx !== index);
    setInterestData(updatedInterestData);
  };

  const handleAddTextbox = () => {
    setInterestData([...interestData, { name: "" }]);
  };

  const getAllInterests = async () => {
    try {
      const { data } = await get_api("interest/all");
      setInterestList(data.allInterests);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllInterests();
  }, []);

  const handleDeleteData = async (id) => {
    try {
      const { data, message } = await delete_api(`interest/${id}`);
      toast.success(message);
      await getAllInterests();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleDeleteData ~ err:", err);
      toast.error(err.message);
    }
  };

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    setEditingKey(record._id);
    setEditingValue(record.name);
  };

  const save = async (record) => {
    try {
      const { data, message } = await put_api(`interest/${record._id}`, {
        name: editingValue,
      });
      toast.success(message);
      await getAllInterests();
      setEditingKey("");
      setEditingValue("");
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={() => save(record)}
            onPressEnter={() => save(record)}
          />
        ) : (
          <div onDoubleClick={() => edit(record)}>
            <h6 className="m-0 text-primary text-capitalize">
              {startCase(text)}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <Popconfirm
            title="Delete the Interest"
            description="Are you sure to delete this Interest?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => handleDeleteData(record._id)}
          >
            <Button
              className="action-btn"
              icon={<i className="fas fa-trash-alt text-danger" />}
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSubmitData = async () => {
    try {
      const removeEmpty = interestData.filter((sd) => sd.name !== "");
      const { data, message } = await post_api("interest/create", removeEmpty);
      setOpenModal(false);
      setInterestData([{ name: "" }]);
      toast.success(message);
      await getAllInterests();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleSubmitData ~ err:", err);
      toast.error(err.message);
    }
  };

  return {
    columns,
    interestsList,
    isLoading,
    openModal,
    setOpenModal,
    interestData,
    setInterestData,
    handleTextboxChange,
    handleRemoveData,
    handleAddTextbox,
    handleSubmitData,
  };
};

export default useInterestsListHook;
