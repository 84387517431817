import { Divider, Spin, Table, Tabs } from "antd";
import React, { useEffect } from "react";
import useLocumShiftsListHook from "./useLocumShiftsListHook";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";

const { TabPane } = Tabs;

const LocumShiftsList = () => {
  const {
    columns,
    locumShifts,
    locumProfile,
    isLoading,
    getShiftsByLocumIdAndStatus,
    activeTab,
    setActiveTab,
  } = useLocumShiftsListHook();

  useEffect(() => {
    getShiftsByLocumIdAndStatus(activeTab);
  }, [activeTab, getShiftsByLocumIdAndStatus]);

  const renderTable = () =>
    isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <Table columns={columns} dataSource={locumShifts} rowKey="_id" />
    );

  return (
    <div className="content mb-0">
      <Row>
          <Col md="12">
            <Card className="card-user">
              <CardBody>
              <h2 className="page-main-title">
                Profile Details :
              </h2>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">First Name</label>
                        <Input
                          className="input-value"
                          value={locumProfile?.first_name}
                          placeholder="First Name"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Last Name</label>
                        <Input
                          className="input-value"
                          value={locumProfile?.last_name}
                          placeholder="Last Name"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Email address</label>
                        <Input
                          className="input-value"
                          placeholder="Email"
                          type="email"
                          value={locumProfile?.email}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Phone</label>
                        <Input
                          className="input-value"
                          placeholder="Phone"
                          value={locumProfile?.phone}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Travel: </label>
                        {/* <Input
                          placeholder="Vaccinated"
                          type="checkbox"
                          checked={companyProfileData?.isVaccinated}
                          className="ml-3"
                        /> */}
                        <span className="static-value">
                          {locumProfile?.userProfile?.[0]?.travel ? "Yes" : "No"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">ABN</label>
                        <Input
                          className="input-value"
                          placeholder="ABN"
                          value={locumProfile?.userProfile?.[0]?.abn}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">AHPRA</label>
                        <Input
                          className="input-value"
                          placeholder="AHPRA"
                          value={locumProfile?.userProfile?.[0]?.ahpra}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">AHPRA verified: </label>
                        <Input
                          className="input-value"
                          placeholder="AHPRA"
                          value={locumProfile?.userProfile?.[0]?.ahpraVerified}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Bio</label>
                        <Input
                          className="input-value"
                          placeholder="Bio"
                          value={locumProfile?.userProfile?.[0]?.bio}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Street Address</label>
                        <Input
                          className="input-value"
                          placeholder="Street Address"
                          value={locumProfile?.userProfile?.[0]?.location?.locationName}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">City, state: </label>
                        <Input
                          className="input-value"
                          placeholder="City, state"
                          value={locumProfile?.userProfile?.[0]?.location?.address}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Therapeutically Endorsed :</label>
                        <span className="static-value">
                          {locumProfile?.userProfile?.[0]?.special_interests?.therapeutically_endorsed ? "Yes" : "No"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Years of Experience :</label>
                        <Input
                          className="input-value"
                          placeholder="Years of Experience"
                          value={locumProfile?.userProfile?.[0]?.special_interests?.years_experience}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Other Interests: </label>
                        <Input
                          className="input-value"
                          placeholder="Other Interests"
                          value={locumProfile?.userProfile?.[0]?.special_interests?.other}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">Interests :</label>
                        {locumProfile?.userProfile?.[0]?.special_interests?.interests?.map((sftwr, idx) => {
                          return (
                            <p className="text-capitalize mb-0">
                              {idx + 1}. {sftwr.name}
                            </p>
                          );
                        })}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">
                          Primary Language :
                        </label>
                        <p className="text-capitalize mb-0">
                          {locumProfile?.userProfile?.[0]?.language?.[0]?.language}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="card-sub-title">
                          Secondary Language :
                        </label>
                        {locumProfile?.userProfile?.[0]?.language
                          ?.splice(1)
                          ?.map((sec, idx) => {
                            return (
                              <p className="text-capitalize mb-0">
                                {idx + 1}. {sec.language}
                              </p>
                            );
                          })}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Divider />
                </Form>
                <h2 className="page-main-title">
                  Shift Details :
                </h2>
                <Tabs className="antd-tabs-wrapper" type="card" activeKey={activeTab} onChange={setActiveTab}>
                  {["accepted", "requested", "completed", "saved", "declined","cancelled"].map(
                    (key) => (
                      <TabPane tab={key.charAt(0).toUpperCase() + key.slice(1)} key={key}>
                        {renderTable()}
                      </TabPane>
                    )
                  )}
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      
    </div>
  );
};

export default LocumShiftsList;
