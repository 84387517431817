/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  // , useLocation
} from "react-router-dom";
import AdminLayout from "layouts/Admin";
import SignIn from "views/signin/SignIn";
import { useAuth } from "context/AuthContext";
import { useEffect } from "react";
import SignUp from "views/signup/SignUp";
import ForgotPassword from "views/forgot_password/ForgotPassword";
import ResetPassword from "views/reset_password/ResetPassword";
import RoutesName from "variables/route";

const { signin, signup, forgotPassword, resetPassword, dashboard } = RoutesName;

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }
  return children;
};
function App() { 
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  // const location = useLocation();
  const location = useLocation();

  useEffect(() => {
    let route;
    const token = localStorage.getItem("access_token");
    if (token && isAuthenticated) {
      if(location.pathname !== dashboard &&
        !["/", signin, signup, forgotPassword, resetPassword].includes(
          location.pathname
      )){
        route = location.pathname;
        }else {
          route = dashboard 
        }
      navigate(route, { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        />
        <Route path={signin} element={<SignIn />} />
        <Route path={signup} element={<SignUp />} />
        <Route path={forgotPassword} element={<ForgotPassword />} />
        <Route path={resetPassword} element={<ResetPassword />} />
        <Route path="*" element={<Navigate to={signin} replace />} />
      </Routes>
    </div>
  );
}

export default App;
