import { Button, Input, Modal, Spin, Table, Upload } from "antd";
import React from "react";
import useLanguagesListHook from "./useLanguagesListHook";
import { Label } from "reactstrap";
import UpploadLogo from "../../assets/img/upload-icon.svg";

const LanguagesList = () => {
  const {
    columns,
    languagesList,
    isLoading,
    openModal,
    setOpenModal,
    handleFlagChange,
    handleFormSubmit,
    countryName,
    setCountryName,
    flag,
    languageName,
    setLanguageName,
    handleCloseModal,
  } = useLanguagesListHook();

  return (
    <div className="content mb-0">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="page-header-wrap">
            <h2 className="page-main-title">Language</h2>
            <Button
              className="header-btn-wrap"
              onClick={() => setOpenModal(true)}
            >
              Add Language
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={languagesList}
            rowKey="_id"
            pagination={false}
            scroll={languagesList.length > 0 ? { y: 670, x: 450 } : undefined}
          />
          <Modal
            className="common-admin-popup"
            open={openModal}
            onCancel={handleCloseModal}
            destroyOnClose
            centered
            footer={[
              <Button
                className="cancel-btn-wrap"
                key="cancel"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>,
              <Button
                className="save-btn-wrap"
                key="submit"
                type="primary"
                onClick={handleFormSubmit}
              >
                Submit
              </Button>,
            ]}
            width={700}
          >
            <div className="add-requirement-field">
              <Label className="common-stepper-label">Country</Label>
              <div className="add-required-field-wrapper">
                <div className="add-requirement-input-wrap">
                  <Input
                    type="text"
                    className="stepper-input-field"
                    placeholder="Enter Country Name"
                    value={countryName}
                    onChange={(e) => setCountryName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="add-requirement-field">
              <Label className="common-stepper-label">Language</Label>
              <div className="add-required-field-wrapper">
                <div className="add-requirement-input-wrap">
                  <Input
                    type="text"
                    className="stepper-input-field"
                    placeholder="Enter Language"
                    value={languageName}
                    onChange={(e) => setLanguageName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="add-requirement-field">
              <Label className="common-stepper-label">Upload Flag</Label>
              <div className="add-required-field-wrapper">
                <div className="add-requirement-input-wrap upload-flag-wrapper">
                  <Upload
                    beforeUpload={() => false} // Prevent auto-upload
                    onChange={handleFlagChange}
                    showUploadList={false}
                  >
                    <Button className="upload-btn">
                      <img src={UpploadLogo} alt="upload icon" />
                      Upload Flag
                    </Button>
                  </Upload>
                  {flag && (
                    <div className="uploaded-file-wrapper">
                      <img
                        src={
                          typeof flag === "string"
                            ? flag
                            : URL.createObjectURL(flag)
                        }
                        alt="Selected Flag"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default LanguagesList;
