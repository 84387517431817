import { Spin, Table } from "antd";
import React from "react";
import useAhpraRequestsListHook from "./useAhpraRequestsListHook";

const AhpraRequestsList = () => {
  const { columns, locumUsersList, isLoading } = useAhpraRequestsListHook();
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">Ahpra Requests</h2>
      {isLoading && <Spin size="large" fullscreen />}
      <Table
        columns={columns}
        dataSource={locumUsersList}
        rowKey="id"
        pagination={false}
        scroll={locumUsersList.length > 0 ? { y: 670, x: 700 } : undefined}
      />
    </div>
  );
};

export default AhpraRequestsList;
