import { Spin, Table } from "antd";
import React from "react";
import useCmsPagesListHook from "./useCmsPagesListHook";

const CmsPagesList = () => {
  const { columns, cmsPagesList, isLoading } = useCmsPagesListHook();
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">
        Cms Pages
      </h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={cmsPagesList}
          rowKey="_id"
          pagination={false}
          scroll={cmsPagesList.length > 0 ? { y: 670 } : undefined}
        />
      )}
    </div>
  );
};

export default CmsPagesList;
