import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Switch } from "antd";
import { post_api } from "api";
import { delete_api } from "api";
import { put_api } from "api";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useFaqListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFaqsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const getAllFaqs = async () => {
    try {
      const { data } = await get_api("faq/all");
      setFaqsList(data.getAllFaqDetails);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllFaqs();
  }, []);

  const handleDeleteData = async (id) => {
    try {
      const { data, message } = await delete_api(`faq/delete/${id}`);
      toast.success(message);
      await getAllFaqs();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleDeleteData ~ err:", err);
      toast.error(err.message);
    }
  };

  const handleEditData = (record) => {
    setQuestion(record.question);
    setAnswer(record.answer);
    setIsPosted(record.isPosted);
    setRecordId(record._id);
    setIsEditing(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setQuestion("");
    setAnswer("");
    setIsPosted(false);
    setOpenModal(false);
    setIsEditing(false);
    setRecordId(null);
  };

  const handleSwitchChange = async (record, checked) => {
    await handleUpdateData({
      question: record.question,
      answer: record.answer,
      _id: record?._id,
      isPosted: checked,
    });
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (text, record) => {
        return (
          <div className="tbl-user-details">
            <h6 className="m-0 text-primary text-capitalize">{text}</h6>
          </div>
        );
      },
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Posted",
      dataIndex: "isPosted",
      key: "isPosted",
      render: (text, record) => {
        return (
          <div key={record._id}>
            <Switch
              className="cmn-switch"
              checked={text}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => handleSwitchChange(record, checked)}
              style={{
                backgroundColor: text ? "#023A28" : undefined,
              }}
            />
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <Button
            className="action-btn"
            onClick={() => handleEditData(record)}
            icon={<i className="fas fa-edit" />}
          />
          <Popconfirm
            title="Delete the FAQ"
            description="Are you sure to delete this FAQ?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => handleDeleteData(record._id)}
          >
            <Button
              className="action-btn"
              icon={<i className="fas fa-times" />}
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSubmitData = async (payload) => {
    try {
      const { data, message } = await post_api("faq/create", payload);
      setOpenModal(false);
      toast.success(message);
      await getAllFaqs();
      return data;
    } catch (err) {
      console.log("🚀 ~ handleSubmitData ~ err:", err);
      toast.error(err.message);
    }
  };

  const handleUpdateData = async (payload) => {
    try {
      const { data, message } = await put_api(`faq/update`, payload); // Adjust API endpoint
      toast.success(message);
      await getAllFaqs();
      setIsEditing(false);
      setRecordId(null);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleFormSubmit = async () => {
    try {
      if (isEditing) {
        await handleUpdateData({ question, answer, _id: recordId, isPosted });
      } else {
        await handleSubmitData({ question, answer, isPosted });
      }
      setQuestion("");
      setAnswer("");
      setIsPosted(false);
      setOpenModal(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return {
    columns,
    faqs,
    isLoading,
    openModal,
    setOpenModal,
    handleFormSubmit,
    question,
    setQuestion,
    answer,
    setAnswer,
    handleCloseModal,
    isPosted,
    setIsPosted,
  };
};

export default useFaqListHook;
