import { Space } from "antd";
import { get_api } from "api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useCmsPagesListHook = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cmsPagesList, setCmsPagesList] = useState(false);

  const getAllCmsPages = async () => {
    try {
      const { data } = await get_api("cms");
      setCmsPagesList(data.cmsPages);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllCmsPages();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div key={record._id}>
          <h6 className="m-0 text-primary text-capitalize">{text}</h6>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(record._id)}
          />
        </Space>
      ),
    },
  ];

  return {
    columns,
    cmsPagesList,
    isLoading,
  };
};

export default useCmsPagesListHook;
