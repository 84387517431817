import { Button, Input, Modal, Spin, Table } from "antd";
import React from "react";
import useInterestsListHook from "./useInterestsListHook";
import { Label } from "reactstrap";
import deleteIcon from "../../assets/img/delete-icon.svg";

const InterestsList = () => {
  const {
    columns,
    interestsList,
    isLoading,
    openModal,
    setOpenModal,
    interestData,
    setInterestData,
    handleTextboxChange,
    handleRemoveData,
    handleAddTextbox,
    handleSubmitData,
  } = useInterestsListHook();

  return (
    <div className="content mb-0">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="page-header-wrap">
            <h2 className="page-main-title">Interests</h2>
            <Button
              className="header-btn-wrap"
              onClick={() => setOpenModal(true)}
            >
              Add Interests
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={interestsList}
            rowKey="_id"
            pagination={false}
            scroll={interestsList?.length > 0 ? { y: 670, x: 450 } : undefined}
          />
          <Modal
            className="common-admin-popup"
            open={openModal}
            onCancel={() => {
              setOpenModal(false);
              setInterestData([{ name: "" }]);
            }}
            centered
            destroyOnClose
            onOk={handleSubmitData}
            width={700}
          >
            <div className="add-requirement-field">
              <Label className="common-stepper-label">Interests</Label>
              {interestData.map((intrs, index) => (
                <div className="add-required-field-wrapper" key={index}>
                  {interestData.length > 1 && (
                    <Button
                      className="delete-icon-wrap"
                      color="link"
                      onClick={() => handleRemoveData(index)}
                    >
                      <img src={deleteIcon} alt="delete icon" />
                    </Button>
                  )}
                  <div className="add-requirement-input-wrap">
                    <Input
                      type="text"
                      className="stepper-input-field"
                      placeholder="Enter Interests"
                      value={intrs.name}
                      onChange={(e) =>
                        handleTextboxChange(e.target.value, index)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            {interestData.length < 3 && (
              <div className="add-other-field" style={{ marginTop: 10 }}>
                <Button
                  className="add-field-btn"
                  color="link"
                  onClick={handleAddTextbox}
                >
                  Add <span className="add-icon">+</span>
                </Button>
              </div>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default InterestsList;
