import { Spin, Table } from "antd";
import React from "react";
import useContactUsListHook from "./useContactUsListHook";

const ContactUsList = () => {
  const { columns, contactUsList, isLoading } = useContactUsListHook();
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">
        Enquiry List
      </h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={contactUsList}
          rowKey="_id"
          pagination={false}
          scroll={contactUsList.length > 0 ? { y: 670, x: 950 } : undefined}
        />
      )}
    </div>
  );
};

export default ContactUsList;
