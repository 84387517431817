import { Spin, Table } from "antd";
import React from "react";
import useActiveLocumsOfShiftsHook from "./useActiveLocumsOfShiftsHook";

const ActiveLocumsOfShifts = () => {
  const { columns, locumsList, isLoading } = useActiveLocumsOfShiftsHook();
  return (
    <div className="content mb-0">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table columns={columns} dataSource={locumsList} rowKey="_id" />
      )}
    </div>
  );
};

export default ActiveLocumsOfShifts;
