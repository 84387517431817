import { Button, Input, Modal, Spin, Switch, Table } from "antd";
import React from "react";
import useFaqListHook from "./useFaqListHook";
import { Label } from "reactstrap";

const FaqList = () => {
  const {
    columns,
    faqs,
    isLoading,
    openModal,
    setOpenModal,
    handleFormSubmit,
    question,
    setQuestion,
    answer,
    setAnswer,
    handleCloseModal,
    isPosted,
    setIsPosted,
  } = useFaqListHook();

  return (
    <div className="content mb-0">
      {isLoading && <Spin fullscreen />}
      <div className="page-header-wrap">
        <h2 className="page-main-title">FAQ</h2>
        <Button className="header-btn-wrap" onClick={() => setOpenModal(true)}>
          Add FAQ
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={faqs}
        rowKey="_id"
        pagination={false}
        scroll={faqs.length > 0 ? { y: 670, x: 450 } : undefined}
      />
      <Modal
        className="common-admin-popup"
        open={openModal}
        onCancel={handleCloseModal}
        destroyOnClose
        centered
        footer={[
          <Button
            className="cancel-btn-wrap"
            key="cancel"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>,
          <Button
            className="save-btn-wrap"
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>,
        ]}
        width={700}
      >
        <div className="add-requirement-field">
          <Label className="common-stepper-label">Question</Label>
          <div className="add-required-field-wrapper">
            <div className="add-requirement-input-wrap">
              <Input
                type="text"
                className="stepper-input-field"
                placeholder="Enter Question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="add-requirement-field">
          <Label className="common-stepper-label">Answer</Label>
          <div className="add-required-field-wrapper">
            <div className="add-requirement-input-wrap">
              <Input.TextArea
                rows={4}
                type="text"
                className="stepper-input-field"
                placeholder="Enter Answer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="add-requirement-field">
          <Label className="common-stepper-label">
            Do you Want to Post this FAQ?
          </Label>
          <div className="add-required-field-wrapper">
            <div className="add-requirement-input-wrap">
              <Switch
                className="cmn-switch"
                checked={isPosted}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked) => setIsPosted(checked)}
                style={{
                  backgroundColor: isPosted ? "#023A28" : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FaqList;
