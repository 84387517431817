import React, { createContext, useEffect, useState } from "react";
const AuthContext = createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("access_token")
  );
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("adminUser")) ?? null
  );

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = (data) => {
    const { tokens, adminUser } = data;
    localStorage.setItem("access_token", tokens.access.token);
    localStorage.setItem("refresh_token", tokens.refresh.token);
    localStorage.setItem("adminUser", JSON.stringify(adminUser));

    setIsAuthenticated(true);
    setUserData(adminUser);
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("adminUser");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userData }}>
      {children}
    </AuthContext.Provider>
  );
};
