import { Spin } from "antd";
import { get_api } from "api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Row, Col } from "reactstrap";

function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(false);
  const getDashboardCounts = async () => {
    try {
      setIsLoading(true);
      const { data } = await get_api(`dashboard`);
      setDashboardData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDashboardCounts();
  }, []);
  return (
    <>
      <div className="content dashboard-content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-yellow"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/business-users")}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Business Users</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">{dashboardData.company}</h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-purple"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/locum-users")}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Locums</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">{dashboardData.locum}</h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-cyan"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Post Shifts</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.postshift}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-green"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/ahpra-requests")}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Ahpra Requests</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.unverfiedAphra}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-pink"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Requested Shifts</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.requestedShiftsCount}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-green"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Accepted Shifts</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.activeShiftsCount}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-red"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Declined Shifts</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.declinedShiftsCount}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card
                  className="card-stats dashboard-cards bg-light-blue"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CardBody>
                    <div className="card-head-wrap">
                      <h6 className="card-title">Saved Shifts</h6>
                    </div>
                    <div className="card-foot-details">
                      <h4 className="total-figure">
                        {dashboardData.savedShiftsCount}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
