import { Avatar, Space } from "antd";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";

const useLocumUsersList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [locumUsersList, setLocumUsersList] = useState(false);

  const getAllLocumUsers = async () => {
    try {
      const { data } = await get_api("locumUser");
      setLocumUsersList(data.locumUsers);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllLocumUsers();
  }, []);

  const columns = [
    {
      title: "Locum Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          <Avatar icon={<UserOutlined />} src={record?.profile_photo} />
          <h6 className="m-0 text-primary text-capitalize">{`${
            record?.userId?.first_name ?? ""
          } ${record?.userId?.last_name ?? ""}`}</h6>
        </div>
      ),
    },
    {
      title: "Locum Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.userId?.email && (
              <p className="m-0">{record?.userId?.email}</p>
            )}
            {record?.userId?.phone && (
              <p className="m-0">{record?.userId?.phone}</p>
            )}
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Ahpra Status",
      dataIndex: "ahpraVerified",
      key: "ahpraVerified",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Registered Type",
      dataIndex: "regfrom",
      key: "regfrom",
      render: (text, record) => (
        <div key={record._id}>{startCase(record?.userId?.type)}</div>
      ),
      align: "center",
    },
    {
      title: "LoggedIn Device Info",
      dataIndex: "deviceInfo",
      key: "deviceInfo",
      render: (text, record) => (
        <div key={record._id}>
          {record?.userId?.deviceDetails?.deviceModel && (
            <div>
              Device: <span>{record?.userId?.deviceDetails?.deviceModel}</span>
            </div>
          )}
          {record?.userId?.deviceDetails?.deviceType && (
            <div>
              OS: <span>{record?.userId?.deviceDetails?.deviceType}</span>
            </div>
          )}
          {record?.userId?.deviceDetails?.versionOS && (
            <div>
              OS Version:{" "}
              <span>{record?.userId?.deviceDetails?.versionOS}</span>
            </div>
          )}
          {record?.userId?.deviceDetails?.currentAppVersion && (
            <div>
              App Version:{" "}
              <span>{record?.userId?.deviceDetails?.currentAppVersion}</span>
            </div>
          )}
          {record?.userId?.deviceDetails?.lastLoggedIn && (
            <div>
              Last Login:{" "}
              <span>
                {dayjs(record?.userId?.deviceDetails?.lastLoggedIn).format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </span>
            </div>
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: "Registered On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>
          {dayjs(record?.userId?.createdAt).format("DD-MM-YYYY")}
        </div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/locum-users/${record?.userId?._id}/shifts`)
            }
          />
        </Space>
      ),
    },
  ];

  return {
    columns,
    locumUsersList,
    isLoading,
  };
};

export default useLocumUsersList;
