import { Avatar, Button, Popconfirm, Space } from "antd";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";

const useAhpraRequestsListHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [locumUsersList, setLocumUsersList] = useState(false);

  const getAllLocumUsers = async () => {
    try {
      const { data } = await get_api("locumUser/profiles");
      setLocumUsersList(data.locumUsers);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllLocumUsers();
  }, []);

  const manageRequest = async (locumId, status) => {
    try {
      setIsLoading(true);
      const res = await get_api(
        `locumUser/updateAfraStatus/${locumId}/${status}`
      );
      getAllLocumUsers();
      setIsLoading(false);
      toast.success(res.message);
      return true;
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };

  const columns = [
    {
      title: "Locum Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div key={record.id} className="tbl-user-details">
          <Avatar
            icon={<UserOutlined />}
            src={record?.userProfile[0]?.profile_photo}
          />
          <h6 className="m-0 text-primary text-capitalize">{`${text ?? ""} ${
            record?.last_name ?? ""
          }`}</h6>
        </div>
      ),
    },
    {
      title: "Locum Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <div key={record.id}>
          <div className="fields-wrapper">
            {record?.email && <p className="m-0">{record.email}</p>}
            {record?.phone && <p className="m-0">{record.phone}</p>}
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Ahpra",
      dataIndex: "ahpra",
      key: "ahpra",
      render: (text, record) => (
        <div key={record.id}>
          <div className="fields-wrapper">
            <p className="m-0">{record?.userProfile[0]?.ahpra}</p>
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record.id}>{dayjs(text).format("DD-MM-YYYY")}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <Button
            className="action-btn"
            icon={<i className="fas fa-check" />}
            style={{ cursor: "pointer" }}
            onClick={() => manageRequest(record.id, "Accepted")}
          />
          <Popconfirm
            title="Reject ahpra request"
            description="Are you sure to reject this ahpra request?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => manageRequest(record.id, "Rejected")}
          >
            <Button
              className="action-btn"
              icon={<i className="fas fa-trash-alt text-danger" />}
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  return {
    columns,
    locumUsersList,
    isLoading,
  };
};

export default useAhpraRequestsListHook;
