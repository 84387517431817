import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import {
  ErrorDisplay,
  getFieldClassName,
} from "components/GeneralComponents/Utils";
import useSignInHook from "./useSignInHook";
import CommonForm from "views/CommonForm";

function SignIn() {
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useSignInHook();

  return (
    <CommonForm>
      <h2 className="card-title text-center mb-4">Sign In</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.email}
            className={getFieldClassName("email", errors, touched)}
          />
          <ErrorDisplay name="email" errors={errors} touched={touched} />
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.password}
            className={getFieldClassName("password", errors, touched)}
          />
          <ErrorDisplay name="password" errors={errors} touched={touched} />
        </FormGroup>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Button color="primary" className="mb-3" type="submit" block>
            Sign In
          </Button>
        </div>
      </Form>
    </CommonForm>
  );
}

export default SignIn;
