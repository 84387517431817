/*eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_api } from "api";
import { toast } from "react-toastify";
import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";

const useActiveLocumsOfShiftsHook = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [locumsList, setLocumsList] = useState([]);

  const getActiveLocumUsersOfShifts = async () => {
    setIsLoading(true);
    try {
      const { data } = await get_api(
        `post-shifts/${params?.shiftId}/active-locum-user`
      );
      setLocumsList(data.activeShiftUsers);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveLocumUsersOfShifts();
  }, []);

  const columns = [
    {
      title: "Locum Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          <Avatar
            icon={<UserOutlined />}
            // src={`${process.env.REACT_APP_BACKEND_BASE_URI}/${record.companyLogo}`}
          />
          <h6 className="m-0 text-primary text-capitalize">{`${record?.locum_id?.first_name ?? ""} ${record?.locum_id?.last_name ?? ""}`}</h6>
        </div>
      ),
    },
    {
      title: "Locum Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.locum_id?.email && (
              <p className="m-0">{record?.locum_id?.email}</p>
            )}
            {record?.locum_id?.phone && (
              <p className="m-0">{record?.locum_id?.phone}</p>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/locum-users/${record?.locum_id?.id}/shifts`)}
          />
        </Space>
      ),
      align: 'center'
    },
  ];

  return {
    columns,
    locumsList,
    isLoading,
  };
};

export default useActiveLocumsOfShiftsHook;
