/*eslint-disable react-hooks/exhaustive-deps*/
import { get_api } from "api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useCompanyProfile = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [companyProfileData, setCompanyProfileData] = useState(false);

  const getCompanyProfileDetail = async () => {
    try {
      const { data } = await get_api(`company-profile/${params?.id}`);
      setCompanyProfileData(data.companyProfileDetail);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCompanyProfileDetail();
  }, []);

  return {
    isLoading,
    companyProfileData,
  };
};

export default useCompanyProfile;
